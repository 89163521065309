import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './AdditionalFiltersButton.module.css';

const AdditionalFiltersButton = props => {
  const { rootClassName, className, label, onSubmit } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <button type="button" className={classes} onClick={onSubmit}>
      {label}
    </button>
  );
};

AdditionalFiltersButton.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
};

AdditionalFiltersButton.propTypes = {
  rootClassName: string,
  className: string,
  label: string,
};

export default AdditionalFiltersButton;
