import React, { useState } from 'react';
import { array, bool, func, node, object, string } from 'prop-types';
import classNames from 'classnames';

import { LISTING_TYPE_COMPANY, propTypes } from '../../../util/types';
import { ensureCompanySearchOrUserType } from '../../../util/data';
import { ListingCard, Modal } from '../../../components';
import InfiniteScroll from 'react-infinite-scroll-component';

import css from './SearchResultsPanel.module.css';
import InquiryForm from '../../ListingPage/InquiryForm/InquiryForm';
import { handleSubmitInquiry } from '../../ListingPage/ListingPage.shared';

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    setActiveListing,
    type,
    isMapOpen,
    onSearchListingsWithOptions,
    mapPinList,
    usersWithTransactions,
    userLoginName,
    connectedSearch,
    onManageDisableScrolling,
    showListingError,
    sendInquiryInProgress,
    sendInquiryError,
    inquirySubmitted,
    routeConfiguration,
    history,
    onSendInquiry,
    onAppendRow,
    onSendConnectionEmail,
    currentUser,
    getListing,
  } = props;

  const isCompanyUser = ensureCompanySearchOrUserType(type);
  const classes = classNames(rootClassName || css.root, className);
  const listingCardsClasses = classNames(
    isCompanyUser ? css.listingCards : css.listingCardsTalent, // visible map
    { [isCompanyUser ? css.listingCardsCollapsed : css.listingCardsTalentCollapsed]: !isMapOpen } // collapsed map
  );

  const [inquiryModalOpen, setInquiryModalOpen] = useState(false);
  const [commonParams,setCommonParams] = useState(null);
  const [sheetsRow,setSheetsRow] = useState(null);
  const [inquiryTitle,setInquiryTitle] = useState(null);

  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    sheetsRow,
    onSendInquiry,
    onAppendRow,
    onSendConnectionEmail,
  });

  // const paginationLinks =
  //   pagination && pagination.totalPages > 1 ? (
  //     <PaginationLinks
  //       className={css.pagination}
  //       pageName="SearchPage"
  //       pagePathParams={{ type }}
  //       pageSearchParams={search}
  //       pagination={pagination}
  //     />
  //   ) : null;

  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const hasMoreListings = pagination ? listings.length < pagination.totalItems : !pagination;
  return (
    <div className={classes}>
      <InfiniteScroll
        className={listingCardsClasses}
        dataLength={listings.length}
        next={onSearchListingsWithOptions}
        hasMore={hasMoreListings}
      >
        {listings.map(l => (
          <ListingCard
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes}
            setActiveListing={setActiveListing}
            type={type}
            mapPinList={mapPinList}
            usersWithTransactions={usersWithTransactions}
            inquiryModalOpen={inquiryModalOpen}
            setInquiryModalOpen={setInquiryModalOpen}
            onManageDisableScrolling={onManageDisableScrolling}
            setCommonParams={setCommonParams}
            setSheetsRow={setSheetsRow}
            routeConfiguration={routeConfiguration}
            history={history}
            currentUser={currentUser}
            setInquiryTitle={setInquiryTitle}
          />
        ))}
      </InfiniteScroll>
      {props.children}

      <Modal
        id="ListingPage.inquiry"
        contentClassName={css.inquiryModalContent}
        isOpen={inquiryModalOpen}
        onClose={() => setInquiryModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <InquiryForm
            className={css.inquiryForm}
            submitButtonWrapperClassName={css.inquirySubmitButtonWrapper}
            listingTitle={inquiryTitle??''}
            sendInquiryError={sendInquiryError}
            onSubmit={onSubmitInquiry}
            inProgress={sendInquiryInProgress}
            inquirySubmitted={inquirySubmitted}
            isCompanyUser={isCompanyUser}
          />
      </Modal>
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
  type: LISTING_TYPE_COMPANY,
  isMapOpen: true,
  mapPinList: null,
  usersWithTransactions: [],
  userLoginName: null,
  connectedSearch: null,
  showListingError: null,
  sendInquiryInProgress: false,
  sendInquiryError: null,
  inquirySubmitted: false,
};

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  type: propTypes.listingType.isRequired,
  isMapOpen: bool.isRequired,
  mapPinList: object,
  usersWithTransactions: array,
  userLoginName: string,
  connectedSearch: string,
  onManageDisableScrolling: func.isRequired,
  showListingError: string,
  sendInquiryInProgress: bool,
  sendInquiryError: string,
  inquirySubmitted: bool,
};

export default SearchResultsPanel;
