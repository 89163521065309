import React from 'react';
import { string, bool } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';

import css from './MainPanelHeader.module.css';

const MainPanelHeader = props => {
  const { rootClassName, className, children, isCompanySearchType } = props;
  const classes = classNames(rootClassName || css.root, className, {
    [css.rootCompany]: isCompanySearchType,
  });

  return (
    <div className={classes}>
      <div className={css.searchLabel}>
        {isCompanySearchType ? (
          <FormattedMessage id="MainPanelHeader.companyLabel" />
        ) : (
          <FormattedMessage id="MainPanelHeader.talentLabel" />
        )}
      </div>
      {children}
    </div>
  );
};

MainPanelHeader.defaultProps = {
  rootClassName: null,
  className: null,
  isCompanySearchType: null,
};

MainPanelHeader.propTypes = {
  rootClassName: string,
  className: string,
  isCompanySearchType: bool.isRequired,
};

export default MainPanelHeader;
